<template>
    <v-container>
        <Detail v-if="request != null" :item="request"/>
    </v-container>
    
</template>
<script>
import Detail from '../../../../components/dashboard/festival/request/Detail';
import { mapState, mapActions } from 'vuex';
import { HOME } from '../../../../routes';
export default {
    components: {
        Detail
    },
    data() {
        return {
            
        }
    },
    created() {
        
        if (this.$user.isGuest) {
            this.$router.push({path: HOME}); //TODO check auth with globar guard
            return;
        }
        this.fetchDetail();
    },
    computed: {
        id() {
            return this.$route.params.id;
        },
        ...mapState('dashboard/festivalRequest', ['request', 'loading'])
    },
    methods: {
        ...mapActions('dashboard/festivalRequest', ['detail']),
        fetchDetail() {
            this.detail({id: this.id,});
        }
    },
}
</script>