<template>
    <v-card class="secondary">
        <v-container>
            <v-row>
                <v-col cols="12">
                    <v-alert
                        color="#171717"
                        border="left"
                        colored-border
                        flat
                        class="body-2"
                        icon="mdi-calendar-heart"
                    >
                        <span>Detalhe Pedido Autorização.</span>
                    </v-alert>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" md="5">
                    <v-text-field
                        :value="item.requestDate"
                        label="Data Pedido"
                        outlined disabled
                    ></v-text-field>
                </v-col>

                <v-col cols="12" md="5">
                    <v-text-field
                        :value="item.validationDate"
                        label="Data Validação"
                        outlined disabled
                    ></v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-switch 
                        :value="`${ item.status == 'APPROVED' ? true : false  }`"
                        readonly
                        :label="item.status"></v-switch>
                </v-col>
            </v-row>

            <v-row>
                <v-col>

                    <v-textarea
                    label="Descrição"
                    auto-grow
                    outlined
                    readonly
                    :value="item.description"
                    row-height="30"
                    ></v-textarea>
                </v-col>

                
            </v-row> 
            <v-row>
                <v-col>
                    <iframe v-if="item.proof" 
                        :src="item.proof" width="100%" height="500px"></iframe>
                    <p v-else>Nenhum Ficheiro Anexado</p>
                </v-col>
            </v-row>       
        </v-container>
    </v-card>
</template>
<script>
export default {
    props: {
        item: Object,
    },
}
</script>